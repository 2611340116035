/* eslint-disable react/forbid-prop-types,react/require-default-props */
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
  TextField as MuiTextField,
} from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Close, FileCopy } from '@material-ui/icons';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import CopyToClipboard from 'react-copy-to-clipboard';
import { alert, email } from '../../../state';
import CkEditor from '../../custom/LazyCkEditor';
import CustomInputField from '../../custom/CustomInputField';
import { TEMPLATE_TEXTS } from './EmailTemplateConstants';

const EditEmailTemplateForm = ({ template, closeModal }) => {
  const dispatch = useDispatch();
  const refForm = useRef(null);

  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  const [, setLoading] = useState(false);
  const [selectedVar, setSelectedVar] = useState('');
  const handleCopy = () => {
    const payload = {
      message: 'Copied to clipboard',
      severity: 'success',
    };
    dispatch(alert.actions.open(payload));
  };

  const getItems = async () => {
    // const res = await dispatch(loyalty.actions.getItems());
    // if (res.payload) {
    //   setItems(res.payload);
    // }
  };
  useEffect(() => {
    getItems();
  }, []);

  return (
    <Formik
      validationSchema={Yup.object().shape({
        subject: Yup.string()
          .required('Required')
          .min(2, 'Minimum characters is 2')
          .max(200, 'Maximum characters is 200'),
      })}
      initialValues={{
        subject: template.subject || '',
        body1: template.body1 || '',
        body2: template.body2 || '',
        body3: template.body3 || '',
      }}
      onSubmit={async (values, { resetForm }) => {
        const formData = values;
        setLoading(true);
        try {
          const resp = await dispatch(
            email.actions.postEmailTemplate({
              ...formData,
              businessId: template.businessId,
              type: template.type,
              culture: template.culture,
            })
          );

          if (resp.payload) {
            openAlert({
              message: 'Saved successfully!',
              severity: 'success',
            });
            resetForm();
            // if (onReload) onReload();
            closeModal(true);
          } else if (resp.error) {
            openAlert({
              message: resp.error.message || 'Failed to save',
              severity: 'error',
            });
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
        return true;
      }}
    >
      {(form) => {
        refForm.current = form;
        const { handleSubmit } = form;
        return (
          <Form>
            <Dialog
              open={!!template}
              onClose={closeModal}
              fullWidth
              maxWidth="lg"
            >
              <DialogTitle>
                <Grid container spacing={4}>
                  <Grid item>
                    App Name:
                    <b> {TEMPLATE_TEXTS[template.type].app}</b>
                  </Grid>
                  <Grid item>
                    Template name:
                    <b> {TEMPLATE_TEXTS[template.type].templateName}</b>
                  </Grid>
                </Grid>

                <Box mt={3}>
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={11}>
                      <MuiTextField
                        select
                        name="signUpCouponPlanId"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          style: { height: 55 },
                          shrink: true,
                        }}
                        label="Select Variable"
                        value={selectedVar}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setSelectedVar(newValue);
                          if (newValue) {
                            navigator.clipboard.writeText(`{{${newValue}}}`);
                            handleCopy();
                          }
                        }}
                      >
                        <MenuItem value={undefined}>---</MenuItem>
                        {template?.variables?.map((val) => (
                          <MenuItem key={val.key} value={val.key}>
                            {val.description}
                          </MenuItem>
                        ))}
                      </MuiTextField>
                    </Grid>
                    <Grid
                      item
                      xs
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Tooltip title="Copy Variable" placement="top" arrow>
                        <span
                          style={{
                            cursor: selectedVar ? 'pointer' : 'not-allowed',
                          }}
                        >
                          <CopyToClipboard
                            onCopy={handleCopy}
                            text={`{{${selectedVar}}}`}
                            disabled={!selectedVar}
                          >
                            <FileCopy
                              cursor={selectedVar ? 'pointer' : 'not-allowed'}
                              style={{ opacity: selectedVar ? 1 : 0.5 }}
                            />
                          </CopyToClipboard>
                        </span>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid xs={12} item>
                    Subject
                  </Grid>
                  <Grid item xs={12}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      fullWidth
                      type="text"
                      removeArrows
                      name="subject"
                      variant="outlined"
                      required
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <Typography style={{ marginTop: 10, marginBottom: 5 }}>
                      Email Body - Top
                    </Typography>
                    <CkEditor name="body1" />
                  </Grid>
                </Grid>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <Typography style={{ marginTop: 10, marginBottom: 5 }}>
                      Email Body - Bottom
                    </Typography>

                    <CkEditor name="body2" />
                  </Grid>
                </Grid>
                <Box mt={4}>
                  <Grid item xs={12} md={12} style={{ textAlign: 'end' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </Grid>
                </Box>
              </DialogContent>
              <IconButton
                aria-label="clear"
                onClick={closeModal}
                style={{
                  position: 'absolute',
                  top: 10,
                  right: 10,
                  width: 20,
                  height: 20,
                }}
              >
                <Close fontSize="small" />
              </IconButton>
            </Dialog>
          </Form>
        );
      }}
    </Formik>
  );
};

EditEmailTemplateForm.propTypes = {
  template: PropTypes.object,
  closeModal: PropTypes.func,
};

export default EditEmailTemplateForm;
